import { Injectable } from '@angular/core'
import { Observable, BehaviorSubject, ReplaySubject, of } from 'rxjs'
import { catchError, map, tap } from 'rxjs/operators'

import { ApiService } from '@app/services/api.service'

export class User {
  id: number
  name: string
  email: string
  role: number

  constructor(attributes: {
    id: number,
    name: string,
    email: string,
    role?: number
  }) {
    attributes.role = attributes.role || 0
    Object.assign(this, attributes)
  }
}

@Injectable()
export class UserService {
  currentUser: Observable<User|null>
  users: Observable<User[]>

  private currentUserSubject = new BehaviorSubject<User|null>(null)
  private usersSubject = new ReplaySubject<User[]>(1)

  constructor(
    private apiService: ApiService
  ) {
    this.currentUser = this.currentUserSubject.asObservable()
    this.users = this.usersSubject.asObservable()
  }

  public loadUser(): Observable<User|null> {
    return this.apiService.get<User>('user', { silent: true }).pipe(
      tap((user) => {
        this.currentUserSubject.next(new User(user))
      }),
      catchError((err) => {
        this.currentUserSubject.next(null)
        return of(null)
      })
    )
  }

  public unloadUser() {
    this.currentUserSubject.next(null)
  }

  public loadUsers(): Observable<User[]> {
    return this.apiService.get<User[]>('users', { silent: true }).pipe(
      map(users => users.map(user => new User(user))),
      tap(users => this.usersSubject.next(users)),
      catchError(() => of([]))
    )
  }
}
