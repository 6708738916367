import { Component } from '@angular/core'
import { Router } from '@angular/router'

import { AuthService, Credentials } from '@app/services/auth.service'

@Component({
  selector: 'app-login.page',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss']
})
export class LoginPage {

  private credentials: Credentials = {
    email: '',
    password: '',
    keep: false
  }

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  login() {
    this.authService.login(this.credentials).subscribe(success => {
      if (success) {
        this.router.navigate([''], { replaceUrl: true })
      } else {
        this.credentials.password = ''
      }
    })
  }
}
