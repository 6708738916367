import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { UserService } from '@app/services/user.service'

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService
  ) {}

  // NOTE: Called once on application startup
  canActivate(): Observable<boolean> {
    return this.userService.loadUser().pipe(
      map((user) => {
        if (user) {
          return true
        } else {
          this.router.navigate(['/login'])
          return false
        }
      })
    )
  }
}
