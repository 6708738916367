import { Injectable } from '@angular/core'
import { Observable, ReplaySubject, of } from 'rxjs'
import { catchError, map, tap } from 'rxjs/operators'

import { ApiService } from '@app/services/api.service'

export interface Cost {
  id: number
  amount: number
  type: number
  time: number
  title: string
  userId?: number
}

export class Episode {
  id: number
  title: string
  closed: number
  costs: Cost[]

  constructor(attributes: {
    id: number,
    title: string,
    closed?: number
  }) {
    attributes.closed = attributes.closed
    Object.assign(this, attributes)
  }

  sumCosts(type: number): number {
    const costs = this.costs
      .filter(cost => cost.type === type)
      .map(cost => cost.amount)
    return costs.length ? costs.reduce((a, b) => a + b) : 0
  }

  getCosts(type: number): Cost[] {
    return this.costs
      .filter(cost => cost.type === type)
  }

  sumUserCosts(userId: number, type: number): number {
    const costs = this.costs
      .filter(cost => cost.type === type && cost.userId === userId)
      .map(cost => cost.amount)
    return costs.length ? costs.reduce((a, b) => a + b) : 0
  }

  getUserCosts(userId: number, type: number): Cost[] {
    return this.costs
      .filter(cost => cost.type === type && cost.userId === userId)
  }
}

@Injectable()
export class EpisodeService {
  episodes: Observable<Episode[]>
  private episodesSubject = new ReplaySubject<Episode[]>(1)

  constructor(
    private apiService: ApiService
  ) {
    this.episodes = this.episodesSubject.asObservable()
  }

  public loadEpisodes(): Observable<Episode[]> {
    return this.apiService.get<Episode[]>('episodes', { silent: true }).pipe(
      map(episodes => episodes.map(episode => new Episode(episode))),
      tap(episodes => this.episodesSubject.next(episodes)),
      catchError(() => of([]))
    )
  }
}
