import { Inject, Injectable, InjectionToken } from '@angular/core'
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http'
import { Router } from '@angular/router'
import { Observable, Subject } from 'rxjs'

export let API_BASE_URL = new InjectionToken<string>('API_BASE_URL')

@Injectable()
export class ApiService {

  constructor(
    @Inject(API_BASE_URL) private baseUrl: string,
    private http: HttpClient,
    private router: Router
  ) {}

  public get<T>(path: string, {silent}: any = {}): Observable<T> {
    return this.request('GET', path, {silent})
  }

  public post<T>(path: string, body: object = {}): Observable<T> {
    return this.request('POST', path, {body})
  }

  public put<T>(path: string, body: object = {}): Observable<T> {
    return this.request('PUT', path, {body})
  }

  public delete(path: string, body: object = {}): Observable<object> {
    return this.request('DELETE', path, {body})
  }

  public request<T>(method: string, path: string, {body, headers, silent}: any = {}): Observable<T> {
    const url = this.baseUrl + path
    headers = this.setDefaultHeaders(headers)
    const subject = new Subject()
    this.http.request<T>(method, url, {body, headers}).subscribe(subject)

    subject.subscribe({error: (res: HttpErrorResponse) => {
      // Ignore errors resulting from silent requests (e.g. getting notifications)
      if (silent) { return }

      // Normally we make sure to logout the user if his token has expired (see AppComponent).
      // Handle edge case where user is logged in in the frontend but logged out in the backend.
      if (res.status === 401) {
        // We cannot use AuthenticationService here because it would cause a circular dependency
        // TODO: perform logout
        this.router.navigate(['/login'])
      } else if (res.status === 404) {
        // TODO: Show 404
        this.router.navigate(['/'])
      }
      this.alertError(res)
    }})

    return subject.asObservable() as Observable<T>
  }

  private alertError(res: any) {
    console.log(this.getErrorMessageFromResponse(res))
  }

  private setDefaultHeaders(headers?: any): HttpHeaders {
    if (!headers) {
      headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8')
    }
    return headers
  }

  private getErrorMessageFromResponse(res: HttpErrorResponse): string {
    let message: string

    // Client-side or network error
    if (res.error instanceof Error) {
      return res.error.message
    }

    // Unsuccessful response code
    const error = res.error
    if (error && error.message) {
      message = `${res.status} ${error.message}`
    } else if (error && error.error) {
      message = `${res.status} ${error.error}`
    } else {
      // No matter what the response, `statusText` and `status` should be present.
      message = message || `${res.status} ${res.statusText}`
    }

    return message
  }
}
