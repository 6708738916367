import { Component, OnInit, OnDestroy } from '@angular/core'
import { ActivatedRoute, Router } from "@angular/router";

import { Subscription, combineLatest } from 'rxjs'
import { first } from 'rxjs/operators'

import { AuthService } from '@app/services/auth.service'
import { EpisodeService, Episode } from '@app/services/episode.service'
import { UserService, User } from '@app/services/user.service'

@Component({
  selector: 'app-episode.page',
  templateUrl: './episode.page.html',
  styleUrls: ['./episode.page.scss']
})
export class EpisodePage implements OnInit, OnDestroy {
  currentUser: User
  users: User[]
  episode: Episode
  slickConfig = {
    arrows: false,
    dots: true,
    edgeFriction: 0.15,
    infinite: false,
    swipeToSlide: true
  }

  private subscriptions: Subscription[] = []

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private episodeService: EpisodeService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      combineLatest(this.userService.currentUser, this.userService.users).subscribe(
        ([currentUser, users]) => {
          this.currentUser = currentUser
          this.users = this.sortUsers(users)
        }
      )
    )
    this.subscriptions.push(
      combineLatest(this.episodeService.episodes, this.route.params).subscribe(
        ([episodes, params]) => {
          this.episode = episodes.find(
            episode => episode.id === parseInt(params.id)
          )
          if (!this.episode) { this.router.navigate(['']) }
        }
      )
    )
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe()
    }
  }

  private sortUsers(users: User[]): User[] {
    return users.sort((a, b) => {
      return a.id === this.currentUser.id || a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
    })
  }

  // TODO Move to shared component
  public logout() {
    this.authService.logout().pipe(first()).subscribe()
  }
}
