import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

import { ApiService } from '@app/services/api.service'
import { UserService } from '@app/services/user.service'

export interface Credentials {
  email: string
  password: string
  keep: false
}

@Injectable()
export class AuthService {

  constructor(
    private router: Router,
    private apiService: ApiService,
    private userService: UserService
  ) {}

  public login(credentials: Credentials): Observable<boolean> {
    return this.apiService.post('login', credentials).pipe(
      map(() => true),
      catchError(() => of(false))
    )
  }

  public logout(redirect = true): Observable<boolean> {
    return this.apiService.get('logout').pipe(
      map(() => {
        this.userService.unloadUser()
        if (redirect) { this.router.navigate(['/login']) }
        return true
      }),
      catchError(() => of(false))
    )
  }
}
