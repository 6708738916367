import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'

import { forkJoin } from 'rxjs'
import { first } from 'rxjs/operators'

import { ApiService } from '@app/services/api.service'
import { AuthService } from '@app/services/auth.service'

@Component({
  selector: 'app-pwd-reset.page',
  templateUrl: './pwd-reset.page.html',
  styleUrls: ['./pwd-reset.page.scss']
})
export class PwdResetPage implements OnInit {

  private token: string
  private password = ''
  private passwordRepeat = ''
  private email = ''

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe(
      params => {
        this.token = params.token
      }
    )
  }

  public resetPassword() {
    const url = window.location.href.replace(/\/$/, '')

    const test = forkJoin({
      logout: this.authService.logout(false).pipe(first()),
      result: this.apiService.post('reset-password', { email: this.email })
    }).subscribe(({logout, result}) => {
      console.log(result)
    })



    // this.router.navigate(['abcdefg'], { relativeTo: this.route })
  }

  public setPassword() {
    // TODO: Call API to save new Password
    // console.log(this.password, this.passwordRepeat)
  }
}
