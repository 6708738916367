import { Injectable } from '@angular/core'
import { Resolve } from '@angular/router'
import { Observable } from 'rxjs'

import { Episode, EpisodeService } from '@app/services/episode.service'

@Injectable()
export class EpisodeResolver implements Resolve<Episode[]> {
  constructor(private episodeService: EpisodeService) {}

  resolve(): Observable<Episode[]> {
    return this.episodeService.loadEpisodes()
  }
}
