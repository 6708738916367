import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

import { AuthGuard } from '@app/guards/auth.guard'
import { LoginGuard } from '@app/guards/login.guard'

import { EpisodeResolver } from '@app/services/episode.resolver'
import { UserResolver } from '@app/services/user.resolver'

import { LoginPage } from '@app/pages/login/login.page'
import { PwdResetPage } from '@app/pages/pwd-reset/pwd-reset.page'
import { EpisodesPage } from '@app/pages/episodes/episodes.page'
import { EpisodePage } from '@app/pages/episodes/episode/episode.page'

const routes: Routes = [
  { path: '',
    canActivate: [AuthGuard],
    resolve: {
      episodes: EpisodeResolver,
      users: UserResolver
    },
    children: [
      { path: '',
        redirectTo: 'episodes',
        pathMatch: 'full'
      },
      { path: 'episodes',
        component: EpisodesPage
      },
      { path: 'episodes/:id',
        component: EpisodePage
      }
    ]
  },
  { path: 'login',
    canActivate: [LoginGuard],
    component: LoginPage
  },
  { path: 'reset-password',
    component: PwdResetPage
  },
  { path: 'reset-password/:token',
    component: PwdResetPage
  },
  { path: '**',
    redirectTo: 'episodes'
  }
]

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ],
  providers: [ AuthGuard, LoginGuard ]
})
export class AppRoutingModule { }
