import { Component, OnInit, OnDestroy } from '@angular/core'

import { Subscription } from 'rxjs'
import { first } from 'rxjs/operators'

import { AuthService } from '@app/services/auth.service'
import { EpisodeService, Episode } from '@app/services/episode.service'
import { UserService, User } from '@app/services/user.service'

@Component({
  selector: 'app-episodes.page',
  templateUrl: './episodes.page.html',
  styleUrls: ['./episodes.page.scss']
})
export class EpisodesPage implements OnInit, OnDestroy{
  currentUser: User
  episodes: Episode[]
  slickConfig = {
    arrows: false,
    dots: true,
    edgeFriction: 0.15,
    infinite: false,
    swipeToSlide: true
  }

  private subscriptions: Subscription[] = []

  constructor(
    private authService: AuthService,
    private episodeService: EpisodeService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.subscriptions.push(this.userService.currentUser.subscribe(
      user => { this.currentUser = user }
    ))
    this.subscriptions.push(this.episodeService.episodes.subscribe(
      episodes => this.episodes = episodes
    ))
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe()
    }
  }

  getActiveEpisodes(): Episode[] {
    const episodes = this.episodes.filter(episode => !episode.closed)
    return episodes.sort((a, b) => {
      return a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1
    })
  }

  getClosedEpisodes(): Episode[] {
    const episodes = this.episodes.filter(episode => episode.closed)
    return episodes.sort((a, b) => {
      if (a.closed < b.closed) return -1;
      if (a.closed > b.closed) return 1;
      return 0;
    })
  }

  public logout() {
    this.authService.logout().pipe(first()).subscribe()
  }
}
