import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { UserService } from '@app/services/user.service'

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService
  ) {}

  canActivate(): Observable<boolean> {
    return this.userService.loadUser().pipe(
      map((user) => {
        if (user) {
          this.router.navigate([''])
          return false
        } else {
          return true
        }
      })
    )
  }
}
