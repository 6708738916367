import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'

import { SlickCarouselModule } from 'ngx-slick-carousel'

import { environment } from '../environments/environment'

import { AppRoutingModule } from '@app/app-routing.module'
import { AppComponent } from '@app/app.component'

import { ApiService, API_BASE_URL } from '@app/services/api.service'
import { AuthService } from '@app/services/auth.service'
import { EpisodeService } from '@app/services/episode.service'
import { UserService } from '@app/services/user.service'

import { EpisodeResolver } from '@app/services/episode.resolver'
import { UserResolver } from '@app/services/user.resolver'

import { LoginPage } from '@app/pages/login/login.page'
import { PwdResetPage } from '@app/pages/pwd-reset/pwd-reset.page'
import { EpisodesPage } from '@app/pages/episodes/episodes.page'
import { EpisodePage } from '@app/pages/episodes/episode/episode.page'

import { HeaderComponent } from '@app/shared/header/header.component'

import { AmountPipe } from '@app/pipes/ampunt.pipe'

@NgModule({
  declarations: [
    AppComponent,
    LoginPage,
    PwdResetPage,
    EpisodesPage,
    EpisodePage,
    HeaderComponent,
    AmountPipe
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    SlickCarouselModule
  ],
  providers: [
    ApiService,
    AuthService,
    EpisodeResolver,
    EpisodeService,
    UserResolver,
    UserService,
    { provide: API_BASE_URL, useValue: environment.apiBaseUrl }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
