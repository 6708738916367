import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'amount' })
export class AmountPipe implements PipeTransform {
  transform(amount: number, signed = false, symbol = ''): string {
    const sign = signed && amount < 0 ? '- ' : ''
    const decimals = amount.toFixed(2).substring(amount.toFixed(2).length - 2)
    const integer = Math.floor(Math.abs(amount))
      .toFixed()
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    symbol = symbol ? ` ${symbol}` : symbol
    return `${sign}${integer},${decimals}${symbol}`
  }
}
